import React, { useState } from "react";

export default function FooterDot() {
  const [privateModel, setPrivateModel] = useState(false);

  return (
    <div>
      <div>
        <div
          className="text-end  mode-drk-light bg-footer pb-2 "
          style={{ right: "0px", paddingRight: "20px" }}
        >
          <a
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="dot-button light-bg bg-gray-100 px-2 m-0 p-1 rounded-pill"
          >
            <i className="bi-three-dots text-gray-500 m-0 font-md" />
          </a>
          <ul className="dropdown-menu dropdown-menu-lg border-light shadow-lg">
            <li className="ms-1">
              <a
                className="dropdown-item text-gray-800 rajdhani-600 lh-24 font-sm"
                href="#"
              >
                {" "}
                Support
              </a>
            </li>
            <li className="ms-1">
              <a
                className="dropdown-item text-gray-800 rajdhani-600 lh-24 font-sm"
                onClick={() => setPrivateModel(!privateModel)}
                href="#"
              >
                {" "}
                Terms & Privacy
              </a>
            </li>
            <li className="ms-1">
              <a
                className="dropdown-item text-gray-800 rajdhani-600 lh-24 font-sm"
                href="#"
              >
                {" "}
                x.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* modal */}
      <div>
        <di className={privateModel ? "modal fade show d-block" : "modal fade"}>
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content rounded-15 ">
              <div className="modal-header">
                <h5 className="text-gray-900 rajdhani-600 dark-text">
                  Terms & Privacy
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setPrivateModel(!privateModel)}
                />
              </div>
              <div className="modal-body border-top  p-3 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex gap-2 w-100 border border-light justify-content-between rounded-10 p-3 bg-gray-100 dark-card mb-3 zoo-hover dark-bg dark-border mt-0">
                  <h5 className="fs-6 rajdhani-600 m-0 dark-text">
                    Neterbridge Terms of Service
                  </h5>
                  <h6 className="m-0 dark-text fs-6">
                    <i class="bi bi-arrow-up-right-circle-fill text-end m-0"></i>
                  </h6>
                </div>
                <div className="d-flex gap-2 w-100 border border-light justify-content-between rounded-10 p-3 bg-gray-100 dark-card mb-3 zoo-hover dark-bg dark-border mt-0">
                  <h5 className="fs-6 rajdhani-600 m-0 dark-text">
                    Neterbridge Privacy Policy
                  </h5>
                  <h6 className="m-0 dark-text fs-6">
                    <i class="bi bi-arrow-up-right-circle-fill text-end m-0"></i>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </di>
      </div>
    </div>
  );
}
